import React from 'react';
import { Flex,Image } from '@chakra-ui/react';
import splash from '../../assets/splash.png';
import logo from '../../assets/logo-guiabahia-2024.jpeg'


const Home = () => {
  return (
    <Flex w='full' marginLeft={60} align={'center'} justify={'center'} >
           <Image w='345px' h='455px' src={logo} alt="logo" />
    </Flex>
  )
}

export default Home